import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/AboutView.vue'),
    children: [
      {
        path: "/new",
        name: "7866-1",
        component: () => import("@/views/new.vue")
      },
      {
        path: "/web",
        name: "7866",
        component: () => import("@/views/web.vue")
      },

      {
        path: '/zsk',
        name: '知识库',
        component: () => import('../g_views/g_home.vue')
      },
      {
        path: '/files',
        name: "文件",
        component: () => import('@/views/file.vue')
      },
      {
        path: "/add",
        name: "新建对话",
        component: () => import("@/views/add.vue")
      }
    ]
  },


  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login.vue"),
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}

router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next()
  } else {
    let token = localStorage.token;
    if (token) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
